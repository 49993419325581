@tailwind base;
@tailwind components;
@tailwind utilities;
$base-fontsize: 16px;
@function calculateRem($size) {
	$remSize: calc($size / $base-fontsize);

	@return #{$remSize}rem;
}
@import './typography.scss';

html {
	--rvh: 1vh;
	overflow: hidden;
	overscroll-behavior: none;

	body {
		overflow: hidden;
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		--bg-base: black;
		overscroll-behavior: none;
		background-color: var(--bg-base);
	}
}

[data-page='gg-slider'] {
	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
	}

	.prespective-1000 {
		perspective: 1000px;
	}

	// NOISE
	.noise {
		animation: grain 10s steps(10) infinite;
		background-image: url('../public/gg-slider-assets/images/noise-full.png');
		background-repeat: repeat;
		height: 200%;
		left: -50%;
		opacity:0.035;
		position: fixed;
		top: -50%;
		width: 200%;
		z-index: 100;
		// transform: translate(-50%, -100%);
		pointer-events: none;
		will-change: transform;
	}

	@keyframes grain {
		0%,
		100% {
			transform: translate(0, 0);
		}

		10% {
			transform: translate(-5%, -10%);
		}
		20% {
			transform: translate(-15%, 5%);
		}
		30% {
			transform: translate(7%, -25%);
		}
		40% {
			transform: translate(-5%, 5%);
		}
		50% {
			transform: translate(-15%, 10%);
		}
		60% {
			transform: translate(15%, 0%);
		}
		70% {
			transform: translate(0%, 15%);
		}
		80% {
			transform: translate(3%, 5%);
		}
		90% {
			transform: translate(-10%, 10%);
		}
	}

	// Text transitions

	.lineParent,
	.lineChild {
		// width: max-content;
		// display: inline !important;
		// overflow: hidden;
	}

	.lineParent {
		// display: inline !important;
		// width: max-content;
		overflow: hidden;
	}

	.lineChild {
		// width: max-content;
		// width: auto;

		@media screen and (max-width: 768px) {
			// width: auto;
		}
	}

	.heightInfinite {
		height: calc(var(--rvh, 1vh) * 100);
	}

	section {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	main {
		position: relative;
	}

	// slides

	.slide {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: calc(var(--rvh, 1vh) * 100);
		pointer-events: none;
	}

	// .slide:not(.active) {
	// 	pointer-events: none;
	// }
	.slide.active {
		pointer-events: auto;
	}

	.slide-wrapper {
		position: relative;
		width: 100vw;
		// height: calc(var(--rvh, 1vh) * 100);
		height: 100dvh;
		pointer-events: none;
	}

	.swiper-image {
		--clipA: 55%;
		--clipB: 41%;
		--clipC: 0%;
		--clipD: 40%;
		--rotate: 4.7deg;
		--y: 48%;
		--x: -1.5vw;
		clip-path: inset(var(--clipA) var(--clipB) var(--clipC) var(--clipD));
		//  transform
		transform: rotate(var(--rotate)) translateY(var(--y))
			translateX(var(--x));
	}

	.react-tooltip {
		background: red;
	}

	.image-double {
		height: calc(100vh - 200px);
		object-fit: cover;

		@media screen and (max-width: 768px) {
			aspect-ratio: 1 / 1;
			width: 100%;
			height: 50%;
		}
	}

	.main-intro {
		&.active {
			pointer-events: all;
		}
	}

	.swiper-wrapper {
		height: 100vh !important;
	}

	@keyframes scrollDown {
		0% {
			transform: scaleY(0);
			transform-origin: 0 0;
		}
		50% {
			transform: scaleY(1);
			transform-origin: 0 0;
		}
		51% {
			transform: scaleY(1);
			transform-origin: 0 100%;
		}
		// 80% {
		// 	transform: scaleY(0);
		// 	transform-origin: 0 100%;
		// }
		100% {
			transform: scaleY(0);
			transform-origin: 0 100%;
		}
	}

	.animate-scrollDown {
		animation: scrollDown 2.5s cubic-bezier(0.72, 0.27, 0.24, 0.97) infinite;
	}

	.map-grad {
		width: 100%;
		height: 100%;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 1;
			background-repeat: no-repeat;
		}

		&:before {
			background: linear-gradient(
				-45deg,
				rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 0.6) 40%,
				rgba(255, 2, 2, 0) 50%,
				rgba(255, 2, 2, 0) 100%
			);
		}

		&:after {
			background: linear-gradient(
				45deg,
				rgba(0, 0, 0, 1) 0%,
				rgba(255, 0, 0, 0) 20%,
				rgba(255, 2, 2, 0) 50%,
				rgba(255, 2, 2, 0) 80%,
				rgba(0, 0, 0, 1) 100%
			);
		}
	}

	.container-full {
		width: 100%;
		padding-left: 100px;
		padding-right: 100px;

		@media screen and (max-width: 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.animation-line-horizontal::before {
		animation: line-horizontal 2s infinite;
	}

	@keyframes line-horizontal {
		0% {
			transform: scaleX(100%);
		}
		50% {
			transform: scaleX(0);
		}
		100% {
			transform: scaleX(100%);
		}
	}
}
