// @import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800&display=swap);
// @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');


@import './fonts.scss';

[data-page='gg-slider'] {
	$font-lexend: 'Lexend', sans-serif;
	$font-lora: 'Lora', serif;

	* {
		font-display: swap;
	}

	a {
		font-family: $font-lexend;
	}

	* {
		font-kerning: none;
		-webkit-text-rendering: optimizeSpeed;
		text-rendering: optimizeSpeed;
		// -webkit-transform: translateZ(0);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span {
		font-kerning: none;
		-webkit-text-rendering: optimizeSpeed;
		text-rendering: optimizeSpeed;
		// -webkit-transform: translateZ(0);
		// transform: translateZ(0);
	}

	.title {
		font-family: 'Lexend', sans-serif;
		font-weight: 700;
		font-size: calculateRem(98px);
		line-height: 1.4;
	}

	// span,
	// p {
	// 	font: 400 16px/22.4px 'Lexend', sans-serif;
	// }

	// * {
	// 	color: white;
	// 	font-family: $font-lexend;
	// }

	// [data-theme='dark'] {
	// 	* {
	// 		color: black;
	// 	}
	// }
	h2 {
		font-size: calculateRem(86px);
		line-height: 1.4;
		font-weight: 700;
		font-family: $font-lexend;

		* {
			font-size: calculateRem(86px);
			line-height: 1.4;
			font-weight: 700;
			font-family: $font-lexend;
			@media screen and (max-width: 767px) {
				font-size: calculateRem(54px);
				line-height: 1.2;
			}
		}
		@media screen and (max-width: 767px) {
			font-size: calculateRem(54px);
			line-height: 1.2;
		}

		@media screen and (min-width: 768px) and (max-width: 1439px) {
			font-size: calculateRem(68px);
			line-height: 1.2;
		}

		@media screen and (min-width: 2160px) {
			font-size: calculateRem(100px);
			line-height: 1.4;
		}
	}

	// h2 {
	// 	font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 	line-height: 1.4;
	// 	font-weight: 700;
	// 	font-family: $font-lexend;
	// 	margin-bottom: clamp(4h, 5vh, 8vh);

	// 	* {
	// 	  font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 	  line-height: 1.4;
	// 	  font-weight: 700;
	// 	  font-family: $font-lexend;
	// 	  @media screen and (max-width: 767px) {
	// 		font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 		line-height: 1.2;
	// 	  }
	// 	}

	// 	@media screen and (min-width: 768px) and (max-width: 1439px) {
	// 	  font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 	  line-height: 1.2
	// 	}

	// 	@media screen and (min-width: 2160px) {
	// 	  font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 	  line-height: 1.4;
	// 	}
	//   }

	// h2 {
	// 	font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 	line-height: 1.4;
	// 	font-weight: 700;
	// 	font-family: $font-lexend;

	// 	* {
	// 		font-size: clamp(calculateRem(54px), 4vw, calculateRem(100px));
	// 		line-height: 1.4;
	// 		font-weight: 700;
	// 		font-family: $font-lexend;
	// 		@media screen and (max-width: 767px) {
	// 			font-size: clamp(5vh, 4vw, 10vh);
	// 			line-height: clamp(5vh, 4vw, 10vh);
	// 		}
	// 	}

	// 	@media screen and (max-width: 767px) {
	// 		margin-bottom: 20px; /* Set margin bottom directly for mobile */
	// 	}

	// 	@media screen and (min-width: 768px) and (max-width: 1439px) {
	// 		margin-bottom: clamp(
	// 			calculateRem(40px),
	// 			5vw,
	// 			calculateRem(80px)
	// 		); /* Clamp margin bottom for desktop */
	// 	}

	// 	@media screen and (min-width: 2160px) {
	// 		margin-bottom: clamp(
	// 			calculateRem(40px),
	// 			5vw,
	// 			calculateRem(80px)
	// 		); /* Clamp margin bottom for larger screens */
	// 	}
	// }

	h3 {
		// font-size: calculateRem(38px);
		// line-height: calculateRem(53.2px);
		// font-weight: 600;
		// font-family: $font-lora;
		font-size: calculateRem(48px);
		line-height: 1.4;
		font-weight: 300;
		font-family: $font-lexend;

		@media screen and (min-width: 768px) and (max-width: 1439px) {
			font-size: calculateRem(38px);
			line-height: 1.2;
		}

		@media screen and (max-width: 767px) {
			font-size: calculateRem(24px);
			line-height: 1.2;
		}

		@media screen and (min-width: 2160px) {
			font-size: calculateRem(56px);
			line-height: 1.4;
		}

		span {
			font-size: calculateRem(48px);
			line-height: 1.4;
			font-weight: 300;
			font-family: $font-lexend strong {
				all: inherit;
				font-weight: 700;
			}

			@media screen and (min-width: 768px) and (max-width: 1439px) {
				font-size: calculateRem(38px);
				line-height: 1.2;
			}

			@media screen and (max-width: 767px) {
				font-size: calculateRem(24px);
				line-height: 1.2;
			}

			@media screen and (min-width: 2160px) {
				font-size: calculateRem(56px);
				line-height: 1.4;
			}

			strong,
			b {
				span {
					all: inherit;
					font-weight: 700;
				}
			}
		}

		.tooltip-parent {
			margin-bottom: 15px;

			@media screen and (max-width: 767px) {
				margin-bottom: 3px;
			}
		}
	}

	// h3 {
	// 	font-size: calculateRem(28px);
	// 	line-height: calculateRem(39.2px);
	// 	font-weight: 600;
	// 	font-family: $font-lora;
	// }

	// Kicker
	h4 {
		font-size: calculateRem(22px);
		line-height: 1.4;
		font-weight: 600;
		font-family: $font-lora;

		@media screen and (min-width: 768px) and (max-width: 1439px) {
			font-size: calculateRem(20px);
			line-height: 1.2;
		}

		@media screen and (max-width: 767px) {
			font-size: calculateRem(18px);
			line-height: 1.2;
		}

		@media screen and (min-width: 2160px) {
			font-size: calculateRem(24px);
			line-height: 1.4;
		}
	}

	h5 {
		font-size: calculateRem(20px);
		line-height: 1.4;
		font-weight: 600;
		font-family: $font-lora;
	}

	h6 {
		font-size: calculateRem(16px);
		line-height: 1.4;
		font-weight: 600;
		font-family: $font-lora;
	}

	.commentary {
		font-size: calculateRem(30px);
		line-height: 1.4;
		font-weight: 300;
		font-family: $font-lexend;

		strong {
			all: inherit;
			font-weight: 700;
		}

		@media screen and (min-width: 768px) and (max-width: 1439px) {
			font-size: calculateRem(22px);
			line-height: 1.2;
		}

		@media screen and (max-width: 767px) {
			font-size: calculateRem(18px);
			line-height: 1.2;
		}
		@media screen and (min-width: 2160px) {
			font-size: calculateRem(36px);
			line-height: 1.4;
		}

		span {
			font-size: calculateRem(30px);
			line-height: 1.4;
			font-weight: 300;
			font-family: $font-lexend;

			@media screen and (min-width: 768px) and (max-width: 1439px) {
				font-size: calculateRem(22px);
				line-height: 1.2;
			}

			@media screen and (max-width: 767px) {
				font-size: calculateRem(18px);
				line-height: 1.2;
			}
			@media screen and (min-width: 2160px) {
				font-size: calculateRem(36px);
				line-height: 1.4;
			}

			strong {
				all: inherit;
				font-weight: 700;
			}
		}
		.tooltip-parent {
			margin-bottom: 10px;

			@media screen and (max-width: 767px) {
				margin-bottom: 0px;
			}
		}
	}

	// .commentary {
	// 	font-size: clamp(22px, 1.1vh, 30px);
	// 	line-height: 1.4;
	// 	font-weight: 300;
	// 	font-family: $font-lexend;

	// 	strong {
	// 		all: inherit;
	// 		font-weight: 700;
	// 	}

	// 	@media screen and (min-width: 768px) and (max-width: 1439px) {
	// 		font-size: 1.1vw;
	// 		line-height: 1.2;
	// 	}

	// 	@media screen and (min-width: 2160px) {
	// 		font-size: 1.7vw;
	// 		line-height: 1.4;
	// 	}

	// 	span,div {
	// 		font-size: clamp(2vw, 1.1vh, 30px);
	// 		line-height: 1.4;
	// 		font-weight: 300;
	// 		font-family: $font-lexend;

	// 		strong {
	// 			all: inherit;
	// 			font-weight: 700;
	// 		}

	// 		@media screen and (max-width: 767px) {
	// 			font-size: clamp(2.1vh, 1.1vh, calculateRem(22px));
	// 			line-height: 1.2;
	// 		}

	// 		@media screen and (min-width: 768px) and (max-width: 1439px) {
	// 			font-size: 2.1vh;
	// 			line-height: 1.4;
	// 		}

	// 		@media screen and (min-width: 2160px) {
	// 			font-size: 1.7vw;
	// 			line-height: 1.4;
	// 		}
	// 	}

	// 	.tooltip-parent {
	// 		margin-bottom: 10px;

	// 		@media screen and (max-width: 767px) {
	// 			margin-bottom: 0px;
	// 		}
	// 	}
	// }

	.kicker {
		font-size: calculateRem(22px);
		line-height: 1.4;
		font-weight: 600;
		font-family: $font-lora;
		margin-bottom: 20px;

		@media screen and (min-width: 768px) and (max-width: 1439px) {
			font-size: calculateRem(18px);
			line-height: 1.2;
		}

		@media screen and (max-width: 767px) {
			font-size: calculateRem(16px);
			line-height: 1.2;
		}

		@media screen and (min-width: 2160px) {
			font-size: calculateRem(24px);
			line-height: 1.4;
		}
	}

	// .kicker {
	// 	font-size: calculateRem(20px);
	// 	line-height: 1.4;
	// 	font-weight: 600;
	// 	font-family: $font-lora;
	// 	margin-bottom: 20px;

	// 	@media screen and (max-width: 767px) {
	// 		font-size: 2.2vh;
	// 		margin-bottom: 1vh;
	// 	}

	// 	@media screen and (min-width: 768px) and (max-width: 1439px) {
	// 		font-size: 1.1vw;
	// 		line-height: 1.2;
	// 		margin-bottom: 20px; /* Maintain the same margin bottom for desktop */
	// 	}

	// 	@media screen and (min-width: 2160px) {
	// 		font-size: 1.7vw;
	// 		line-height: 1.4;
	// 		margin-bottom: 20px; /* Maintain the same margin bottom for larger screens */
	// 	}
	// }

	.react-tooltip {
		--rt-opacity: 1;

		* {
			font-size: 16px;
			line-height: 1.4;
			font-family: $font-lexend;
		}

		a {
			color: #b10101;
			// position: relative;
			border-bottom: 1px solid #b10101;
			font-weight: 700;
			// display: inline-block;
			text-transform: uppercase;
		}
	}

	* {
		text-wrap: pretty;
	}
}
